<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="500"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text
                v-if="loading"
            >
                <v-col
                    class="text-subtitle-1 text-center"
                    cols="12"
                >
                    {{ $t("Загрузка...") }}
                </v-col>

                <v-col cols="12">
                    <v-progress-linear
                        color="#a94442"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-col>

            </v-card-text>

            <v-card-text
                v-else
                class="wrapperFormModal"
            >
                 <v-card flat>
                    <v-card-text>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Профайл") }}</label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <v-select
                                    v-model="profile"
                                    :items="profiles"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    hide-details
                                    outlined
                                    dense
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >
                                </v-select>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Владелец") }}</label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <v-select
                                    v-model="dn"
                                    :items="distinguishedNames"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    hide-details
                                    outlined
                                    dense
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    :placeholder="distinguishedNamesLoading ? 'Загрузка...' : ''"
                                    :disabled="profile == null"
                                    class="select-outer-icon-inner"
                                >
                                    <template slot="append-outer">
                                        <div class="d-flex align-center" @click="updateDNs">
                                            <v-icon>fas fa-redo</v-icon>
                                        </div>    
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>

                    </v-card-text>
                 </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    v-if="!loading && profile && dn"
                    :loading="pendingData"
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                >
                    {{ $t("Загрузить") }}

                    <template v-slot:loader>
                        <semipolar-spinner
                            v-if="pendingData"
                            :animation-duration="2000"
                            :size="50"
                            color="white"
                            class="auth-loader"
                        />
                    </template>

                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SemipolarSpinner } from 'epic-spinners';

export default {
    name: "ImportCertificateDlg",
    components: {
        SemipolarSpinner
    },
    data () {
        return {
            title: "Загрузка_ключа",

            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            }
        }
    },
    computed: {
        ...mapGetters('global/dialogs/importCertificate',
        { 
            visible: 'isVisible',
            loading: 'isLoading',
            profiles: 'getProfiles',
            distinguishedNames: 'getDistinguishedNames',
            distinguishedNamesLoading: 'isDistinguishedNamesLoading',
            pendingData: 'isPendingData'
        }),
        profile: {
            get: function() {
                return this.$store.getters['global/dialogs/importCertificate/getProfile'];
            },
            set: function(v) {
                this.$store.dispatch('global/dialogs/importCertificate/onProfileSelect', v);
            }
        },
        dn: {
            get: function() {
                return this.$store.getters['global/dialogs/importCertificate/getDistinguishedName'];
            },
            set: function(v) {
                this.$store.commit('global/dialogs/importCertificate/SET_DISTINGUISHED_NAME', v);
            }
        }
    },
    methods: {
        ...mapActions('global/dialogs/importCertificate', ['ok', 'cancel']),
        updateDNs() {
            this.$store.dispatch('global/dialogs/importCertificate/onProfileSelect', this.profile);
        }
    }
}
</script>